import PropTypes from 'prop-types';
import React from 'react';
import { StylesProvider } from '@ci/styles';
import { Box, Grid, Heading, Text } from '@myci/ui-components';
import { Message, isRtl } from '@myci/intl';
import { graphql } from 'gatsby';
import { useIntl } from 'react-intl';

import m from '../messages';
import { renderer } from '../renderer';
import { theme } from '../theme';

const SadImage = () => {
	const intl = useIntl();

	return (
		<img
			src={require('../assets/ill_coming_soon.svg')}
			alt={<Message {...m.notFound} />}
			placeholder={intl.formatMessage(m.notFoundIconAlt)}
			height={221}
			width={206}
		/>
	);
};

const NotFoundPage = () => (
	<StylesProvider theme={theme} renderer={renderer} direction={isRtl() ? 'rtl' : 'ltr'}>
		<Grid
			className="content-fullscreen"
			flexDirection={{ xs: 'column', md: 'row' }}
			alignItems="center"
		>
			<Box ml={{ md: 3, xl: 5 }} mr={{ md: 5 }} order={{ xs: 2, md: 1 }}>
				<Heading as="h1" mb={{ xs: 3, md: 4 }}>
					<Message {...m.notFound} />
				</Heading>

				<Text>
					<Message {...m.notFoundDescription} />
				</Text>
			</Box>

			<Box mt={{ sm: 'n4' }} mb={{ xs: 3, md: 0 }} order={{ xs: 1, md: 2 }}>
				<SadImage />
			</Box>
		</Grid>
	</StylesProvider>
);

NotFoundPage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`;

export default NotFoundPage;
